// Gatsby requirements
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

// Storyblok preview
import { storyblokEditable, useStoryblokState } from "gatsby-source-storyblok"

// Components
import Layout from '../components/layout/layout'
import Seo from '../components/seo'

// Storyblok Components
import StoryblokComponents from 'utils/StoryblokComponents'


const PageTemplate = ({ data }) => {

    const [previewStory, setPreviewStory] = useState(null);

    let story = useStoryblokState(data.storyblokEntry)

    useEffect(() => {
        if (window.location.search.includes('_storyblok')) {
            setPreviewStory(null);
        }
    }, [story]);

    useEffect(() => {
        if (window.location.search.includes('_storyblok')) {
            const url = `https://api.storyblok.com/v2/cdn/stories/${data.storyblokEntry.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_ACCESS_TOKEN}&cv=${Date.now()}`;
        
            const fetchData = async () => {
                try {
                    const response = await fetch(url);
                    const json = await response.json();
                    setPreviewStory(json.story);
                } catch (error) {
                    console.log("error", error);
                }
            };
        
            fetchData();
        }
    }, []);

    // Gives nofollow to links that have been given nofollow class in storyblok
    useEffect(() => {
        var els = document.querySelectorAll('.nofollow');

        for (var i = 0; i < els.length; i++) {
            els[i].classList.remove('nofollow');
            els[i].parentNode.setAttribute("rel", "nofollow");
        };
    },[]);

    const content = previewStory ? previewStory?.content : story.content;

    return (
        <Layout
            en={data.storyblokEntry?.full_slug?.startsWith('en/')}
            alternateSlug={data.storyblokEntry?.content?.translation_of?.cached_url === 'home' ? '/' : `/${data.storyblokEntry?.content?.translation_of?.cached_url}`}
        >

            <div {...storyblokEditable(story.content)}>

                <Seo
                    title={data.storyblokEntry?.field_meta_title_string}
                    description={data.storyblokEntry?.field_meta_description_string}
                    thumbnail={data.storyblokEntry.content?.meta_image?.filename}
                    type="website"
                    url={data.storyblokEntry?.full_slug === 'home' ? '/' : `/${data.storyblokEntry?.full_slug}`}
                    noindex={data.storyblokEntry?.content?.noindex}
                    en={data.storyblokEntry?.full_slug?.startsWith('en/')}
                    alternateSlug={data.storyblokEntry?.content?.translation_of?.cached_url === 'home' ? '/' : `/${data.storyblokEntry?.content?.translation_of?.cached_url}`}
                />

                <main id="content" className="page">

                    <StoryblokComponents
                        content={content?.blocks}
                        en={data.storyblokEntry?.full_slug?.startsWith('en/')}
                        currentPageName={data.storyblokEntry?.name}
                        currentPage={data.storyblokEntry?.full_slug === 'home' ? '/' : `/${data.storyblokEntry?.full_slug}`}
                        offers={data?.offers?.edges?.length > 0 ? data.offers.edges : null}
                        offerPosition={content?.offerPosition ? Number(content.offerPosition) : 2}
                        offerText={(content?.enableCustomOfferText && content?.offerText) ? content?.offerText : null}
                        offerBackgroundColor={content?.offerBackgroundColor}
                        offerAnchorId={content?.offerAnchorId}
                        offerRemoveTopMargin={content?.offerRemoveTopMargin}
                        offerRemoveBottomMargin={content?.offerRemoveBottomMargin}
                        currentPageId={previewStory ? previewStory?.uuid : story.uuid}
                    />
    
                </main>

            </div>

        </Layout>
    )
}

// Page query, the blocks are in "content" as JSON
export const query = graphql`
  query ($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
        full_slug
        field_meta_description_string
        field_meta_title_string
        field_noindex_boolean
        name
        content
        id
        uuid
        internalId
    }
    offers: allStoryblokEntry(filter: {field_component: {eq: "Offer"}}) {
        edges {
            node {
                content
            }
        }
    }
  }
`

export default PageTemplate
